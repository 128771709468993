import * as React from "react";
import {Main} from "../layout/Main";
import {Game} from "../entities/Game";

const game = new Game();

export function Play() {
    const [, setRerenderToken] = React.useState({});
    React.useEffect(() => {
        const intervalHandle = setInterval(() => game.doTick(() => setRerenderToken({})),
            game.tickTimeMS);
        return () => {
            clearInterval(intervalHandle);
        }
    }, []);

    React.useEffect(() => {
        const listener = (genericEvent: Event) => {
            if (genericEvent.hasOwnProperty("gamepad")) {
                const gamepadEvent = genericEvent as GamepadEvent;
                console.log("Gamepad connected at index %d: %s. %d buttons, %d axes.",
                    gamepadEvent.gamepad.index, gamepadEvent.gamepad.id,
                    gamepadEvent.gamepad.buttons.length, gamepadEvent.gamepad.axes.length);
            }
        };
        window.addEventListener("gamepadconnected", listener);
        return () => {
            window.removeEventListener("gamepadconnected", listener);
        }
    });
    return (<>
        <Main>
            <svg width="100%" height="100%" viewBox={`0 0 ${game.maxX} ${game.maxY}`}>
                <rect width={game.maxX} height={game.maxY} fill="none" stroke="black"/>
                {game.entites.map((entity, index) => entity.render())}
            </svg>
        </Main>
    </>);
}
