import {Route, Switch} from "react-router";
import * as React from "react";
import {ScreenBox} from "../layout/Screenbox";
import {Play} from "../views/Play";

export default function App() {
    return (
        <Switch>
            <Route path="/">
                <ScreenBox fullScreen>
                    <Play/>
                </ScreenBox>
            </Route>
        </Switch>
    );
}