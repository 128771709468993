import {Arena} from "./Arena";
import {PositionedEntity} from "./PositionedEntity";
import {Bomb} from "./Bomb";
import {Powerup, PowerupType} from "./Powerup";

export enum TileType {
    GROUND = " ",
    START = "S",
    WALL = "o",
    FIXED_WALL = "X",
}

export class Tile {
    get type(): TileType {
        return this._type;
    }

    readonly tx: number;
    readonly ty: number;
    readonly arena: Arena;
    readonly who: PositionedEntity[] = [];
    private _type: TileType;


    constructor(arena: Arena, tx: number, ty: number, type?: TileType) {
        this.arena = arena;
        this.tx = tx;
        this.ty = ty;
        this._type = type || TileType.GROUND;
    }

    getCenter(): { x: number, y: number } {
        return {x: (this.tx + 0.5) * this.arena.tileSize, y: (this.ty + 0.5) * this.arena.tileSize};
    }

    collides(other: PositionedEntity) {
        switch (this.type) {
            case TileType.GROUND:
            case TileType.START:
                return false;
            case TileType.WALL:
            case TileType.FIXED_WALL:
            default:
                return true;
        }
    }

    hit(bomb: Bomb): boolean {
        if (this.type === TileType.WALL) {
            this._type = TileType.GROUND;
            let powerup;
            if (Math.random() < 1 / 6) {
                powerup = new Powerup(this.arena.game, PowerupType.BOMB_MAX);
            } else if (Math.random() < 1 / 6) {
                powerup = new Powerup(this.arena.game, PowerupType.BOMB_POWER);
            } else if (Math.random() < 1 / 6) {
                powerup = new Powerup(this.arena.game, PowerupType.SPEED);
            } else if (Math.random() < 1 / 18) {
                powerup = new Powerup(this.arena.game, PowerupType.BOMB_MAX_DOWN);
            } else if (Math.random() < 1 / 18) {
                powerup = new Powerup(this.arena.game, PowerupType.BOMB_POWER_DOWN);
            } else if (Math.random() < 1 / 18) {
                powerup = new Powerup(this.arena.game, PowerupType.SPEED_DOWN);
            }
            if (powerup) {
                powerup.x = this.getCenter().x;
                powerup.y = this.getCenter().y;
            }
            this.arena.game.rerender();
            return true;
        } else {
            return !this.blocks(bomb);
        }
    }

    blocks(bomb: Bomb): boolean {
        return this.type !== TileType.GROUND && this.type !== TileType.START;
    }
}