import * as React from "react";
import {Game} from "./Game";
import {PositionedEntity} from "./PositionedEntity";
import {Bomb} from "./Bomb";
import * as _ from "lodash";
import {Entity} from "./Entity";
import {ImageComponent} from "../components/ImageComponent";

export class Bomber extends PositionedEntity {
    public readonly bombs: Bomb[] = [];

    speed = 2;
    maxBombs = 1;
    power = 1;
    canKick = false;
    private moveDirection: number = 0;

    doTick() {
        const gamepads: Array<Gamepad | null> = Array.from(navigator.getGamepads());
        const gamepad = gamepads.find(pad => pad?.index === this.gamepadIndex);
        if (gamepad) {
            const tile = this.tile;
            const bombs = tile?.who.filter(entity => entity instanceof Bomb);
            this.moveXY(gamepad.axes[0], gamepad.axes[1], this.speed);
            if (gamepad.buttons[0]?.pressed && this.bombs.length < this.maxBombs) {
                if (!bombs || bombs.length === 0) {
                    const center = tile?.getCenter();
                    if (center) {
                        const bomb = new Bomb(this, this.power);
                        bomb.x = center.x;
                        bomb.y = center.y;
                        this.game.rerender();
                    }
                }
            }
        }
    }

    hit(): boolean {
        super.hit();
        this.delete();
        return false;
    }


    render(): React.ReactNode {
        return <ImageComponent
            entity={this}
            imageName={() => this.imageName()}
            // color={this.color}
            direction={() => this.moveDirection}
            key={"entity-" + this.id}
        />
    }

    protected imageName(): string {
        const frame = Math.floor(((this.x + this.y) / 20) % 2 + 1);
        return "img/bomber-" + (this.avatar ? this.avatar + "-" + frame : this.gamepadIndex) + ".png";
    }

    public readonly gamepadIndex: number;
    public readonly color: string;
    public readonly avatar?: string;

    constructor(game: Game, gamepadIndex: number, color: string, avatar?: string) {
        super(game);
        this.gamepadIndex = gamepadIndex;
        this.color = color;
        this.avatar = avatar;
    }

    delete() {
        _.remove(this.game.bombers, this);
        super.delete();
    }

    private moveXY(x: number, y: number, speed: number) {
        const d = Math.abs(x) + Math.abs(y);
        const delta = Math.max(1, d);
        if (delta > 0) {
            this.move(x / delta, "x", speed, this.game.arena.tileSize / 3);
            this.move(y / delta, "y", speed, this.game.arena.tileSize / 3);
            this.moveDirection = Math.atan2(y / d, x / d);
        }
    }

    collides(other: Entity) {
        return !(other instanceof Bomber);
    }
}