import {Entity} from "./Entity";
import {Game} from "./Game";
import {Tile, TileType} from "./Tile";
import * as React from "react";
import {PositionedEntity} from "./PositionedEntity";

const arenas = [`
S ooooooooooooooooo S
 XoXoXoXoXoXoXoXoXoX 
ooooooooooooooooooooo
oXoXoXoXoXoXoXoXoXoXo
ooooooooooooooooooooo
oXoXoXoXoXoXoXoXoXoXo
ooooooooo Soooooooooo
oXoXoXoXoX XoXoXoXoXo
ooooooooooooooooooooo
oXoXoXoXoXoXoXoXoXoXo
ooooooooooooooooooooo
oXoXoXoXoXoXoXoXoXoXo
ooooooooooooooooooooo
 XoXoXoXoXoXoXoXoXoX 
S ooooooooooooooooo S
`,`
So  o ooooXooooo    S
 X ooooooooooooo oX  
  oooooooo oooooooo o
oooX oo ooX ooooXoooo
o ooooo oooooooooo o 
ooo oX o o o oXooooooo
o oo oooooXooo ooo ooo
oooooo Xo o Xoooooooo
oooooo o S  ooo oooooo
oo ooooo XX oo o oo  
oooo oo ooooooo oooo 
ooooooo XooX ooooooo 
oooo  oooooooo  oooo 
 ooo oXoooXooXoo ooo
S oooo oooooooooooooS
`,` 
S ooooooooooooooooo S
 oooooooooooooooooo  
ooooooooooooooooooooo
ooooooooooooooooooooo 
ooooooooooooooooooooo
oooooooooo oooooooooo
ooooooooo Soooooooooo
ooooooooooooooooooooo
ooooooooooooooooooooo
ooooooooooooooooooooo
ooooooooooooooooooooo
ooooooooooooooooooooo
 ooooooooooooooooooo 
S ooooooooooooooooo S
`,
    `
S                   S
 X X X X X X X X X X 
                     
 X X X X X X X X X X 
                     
 X X X X X X X X X X 
          S          
 X X X X X X X X X X 
                     
 X X X X X X X X X X 
                     
 X X X X X X X X X X 
                     
 X X X X X X X X X X 
S                   S
`];

export class Arena extends Entity {
    readonly tileSize = 30;
    readonly width = 21;
    readonly height = 15;
    readonly tiles: Tile[][];

    constructor(game: Game) {
        super(game);
        this.tiles = [];
        const template = arenas[1].split("\n");
        for (let ty = 0; ty < this.height; ty++) {
            this.tiles.push([]);
            for (let tx = 0; tx < this.width; tx++) {
                this.tiles[ty].push(new Tile(this, tx, ty, template[ty + 1][tx] as TileType));
            }
        }
    }

    doTick(): void {
    }

    getTileOf(entity: PositionedEntity): Tile | undefined {
        const x = entity.x;
        const y = entity.y;
        return this.getTileAt(x, y);
    }

    getTileAt(x: number, y: number) {
        const tx = Math.floor(x / this.tileSize);
        const ty = Math.floor(y / this.tileSize);
        return this.getTile(tx, ty);
    }

    getTile(tx: number, ty: number) {
        if (tx >= 0 && tx < this.width && ty >= 0 && ty < this.height) {
            return this.tiles[ty][tx];
        } else {
            return undefined;
        }
    }

    render() {
        return <g key="arena">
            {this.tiles.map((row, ty) => row.map((tile, tx) => {
                let color;
                let imageSuffix: string = tile.type;
                let missingImage = false;
                switch (tile.type) {
                    case TileType.WALL:
                        color = "grey";
                        break;
                    case TileType.FIXED_WALL:
                        color = "black";
                        break;
                    default:
                        color = "white";
                        imageSuffix = " -" + (((tx + ty) % 2) + 1);
                        break;
                }
                console.log(imageSuffix);
                const tileSize = this.tileSize;
                const x = tx * tileSize;
                const y = ty * tileSize;
                return (<React.Fragment key={`${tx}-${ty}`}>
                    {missingImage && <rect
                        x={x}
                        y={y}
                        width={tileSize}
                        height={tileSize}
                        fill={color}
                    />}
                    <image
                        href={`img/tile-${imageSuffix}.png`}
                        x={x}
                        y={y}
                        width={tileSize + 1}
                        height={tileSize + 1}
                        onError={e => (e.target as any).style.display = 'none'}
                    />
                </React.Fragment>)
            }))}
        </g>;
    }

}