import * as React from "react";
import {createStyles, makeStyles, Theme, useTheme} from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import MenuIcon from "@material-ui/icons/Menu";
import Logo from "@material-ui/icons/WbSunny";
import {useMediaQuery} from "@material-ui/core";
import AccountCircle from "@material-ui/icons/AccountCircle";
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import {useHistory} from "react-router";

const drawerWidth = 220;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        menuButton: {
            marginRight: theme.spacing(2),
        },
        title: {
            flexGrow: 1,
        },
        drawer: {
            width: 0,
            transition: "width 0.2s ease-out",
        },
        drawerOpen: {
            [theme.breakpoints.up('sm')]: {
                width: drawerWidth,
                flexShrink: 0,
            },
            transition: "width 0.2s ease-out",
        },
        appBarOnTop: {
            zIndex: 2000,
        },
        // necessary for content to be below app bar
        toolbar: theme.mixins.toolbar,
        drawerPaper: {
            width: drawerWidth,
            zIndex: 800,
        },
        content: {
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            // height: "100%"
        },
    }),
);

function toggleFullScreen() {
    if (
        !document.fullscreenElement
    ) {
        // current working methods
        if (document.documentElement.requestFullscreen) {
            document.documentElement.requestFullscreen().catch(
                () => console.warn("Switching to fullscreen failed."));
        }
    } else {
        if (document.exitFullscreen) {
            document.exitFullscreen().catch(
                () => console.warn("Exiting fullscreen failed."));
        }
    }
}


const Menu = (props: { onClick: () => true }) => {
    const classes = useStyles();
    const history = useHistory();
    return (
        <div>
            <div className={classes.toolbar}/>
            <Divider/>
            <List>
                <ListItem button onClick={() => props.onClick() && history.push("/")}>
                    <ListItemIcon><AccountCircle/></ListItemIcon>
                    <ListItemText primary="Spiel"/>
                </ListItem>
            </List>
        </div>
    );
};

export function Bar(props: { children: React.ReactNode }) {
    const classes = useStyles();
    const theme = useTheme();
    const isWideScreen = useMediaQuery(theme.breakpoints.up('sm'));
    const [menuOpen, setMenuOpen] = React.useState(false);

    const handleDrawerToggle = (): true => {
        setMenuOpen(!menuOpen);
        return true;
    };
    return (
        <>
            <CssBaseline/>
            <AppBar position="fixed" className={menuOpen && !isWideScreen ? classes.appBarOnTop : undefined}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        className={classes.menuButton}
                    >
                        <MenuIcon/>
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        <Logo
                            style={{marginBottom: "-4px"}}/> Bomberman
                    </Typography>
                    <IconButton
                        color="inherit"
                        aria-label="fullscreen"
                        onClick={toggleFullScreen}
                    >
                        <FullscreenIcon/>
                    </IconButton>
                </Toolbar>
            </AppBar>
            <nav className={menuOpen ? classes.drawerOpen : classes.drawer} aria-label="mailbox folders">
                {!isWideScreen &&
                <Drawer
                    variant="temporary"
                    anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                    open={menuOpen}
                    onClose={handleDrawerToggle}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                >
                    <Menu onClick={handleDrawerToggle}/>
                </Drawer>
                }
                {isWideScreen && menuOpen &&
                <Drawer
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                    variant="permanent"
                    open
                >
                    <Menu onClick={(): true => true}/>
                </Drawer>
                }
            </nav>

            <main className={classes.content}>
                <div className={classes.toolbar}/>
                {props.children}
            </main>
        </>
    );
}
