import {Bomber} from "./Bomber";
import * as _ from "lodash";
import {TimedEntity} from "./TimedEntity";
import {Explosion} from "./Explosion";
import {Tile} from "./Tile";


export class Bomb extends TimedEntity {
    private bomber: Bomber;
    private power: number;
    private exploded = false;
    private speedX = 0;
    private speedY = 0;

    constructor(bomber: Bomber, power: number) {
        super(bomber.game, 5 * 1000 / bomber.game.tickTimeMS);
        this.power = power;
        this.bomber = bomber;
        bomber.bombs.push(this);
    }

    protected imageName(): string {
        return "img/bomb.png";
    }

    doTick() {
        this.move(this.speedX, "x", 5, this.game.arena.tileSize / 2-1);
        this.move(this.speedY, "y", 5, this.game.arena.tileSize / 2-1);
        super.doTick();
    }

    hit() {
        this.delete();
        return true;
    }

    delete() {
        _.remove(this.bomber.bombs, this);
        this.explode();
        super.delete();
    }

    private explode() {
        if (this.exploded) return;
        this.exploded = true;
        const tile = this.game.arena.getTileOf(this);
        this.explosionTile(tile);
        this.explodeTo(this.power, tile, -1, 0);
        this.explodeTo(this.power, tile, 1, 0);
        this.explodeTo(this.power, tile, 0, -1);
        this.explodeTo(this.power, tile, 0, 1);
    }

    private explosionTile(tile: Tile | undefined) {
        if (tile) {
            const entities = [...tile.who];
            if (tile.hit(this)) {
                const blocked = entities.map(entity => entity.hit()).indexOf(true) >= 0;
                new Explosion(this.game, tile);
                return blocked;
            }
        }
        return true;
    }

    private explodeTo(power: number, tile: Tile | undefined, dx: number, dy: number) {
        if (tile && power > 0) {
            const arena = this.game.arena;
            const nextTile = arena.getTile(tile.tx + dx, tile.ty + dy);
            const blockedByTile = nextTile?.blocks(this);
            const blockedByEntity = this.explosionTile(nextTile);
            if (!blockedByTile && !blockedByEntity) {
                this.explodeTo(power - 1, nextTile, dx, dy);
            }
        }
    }
}