import {Bomber} from "./Bomber";
import {Entity} from "./Entity";
import {Arena} from "./Arena";
import {TileType} from "./Tile";

export class Game {
    readonly entites: Entity[] = [];
    readonly bombers: Bomber[] = [];
    readonly colors = ["lightblue", "red", "grey", "green", "purple", "black"];
    readonly avatars = ["Meeriebomber"];
    readonly maxX: number;
    readonly maxY: number;
    public tick = 0;
    private shouldRerender = false;

    public readonly arena: Arena;
    public readonly tickTimeMS = 20;

    constructor() {
        this.arena = new Arena(this);
        this.maxX = this.arena.width * this.arena.tileSize;
        this.maxY = this.arena.height * this.arena.tileSize;
    }

    readonly doTick = (rerender: () => void) => {
        this.tick++;
        const gamepads: Array<Gamepad | null> = Array.from(navigator.getGamepads());
        for (let i = 0; i < 10; i++) {
            const gamepad = gamepads[i];
            if (gamepad) {
                if (!this.bombers.find(bomber => bomber.gamepadIndex === gamepad.index)) {
                    const bomber = new Bomber(this, gamepad.index, this.colors[i], this.avatars[i]);
                    this.bombers.push(bomber);
                    const startTiles = this.arena.tiles.flatMap(row => row)
                        .filter(tile => tile.type === TileType.START);
                    if ( startTiles.length === 5 )
                    {
                        const mid = startTiles[2];
                        startTiles[2] = startTiles[4];
                        startTiles[4] = mid;
                    }
                    const start = startTiles[i%startTiles.length];
                    bomber.x = start.getCenter().x;
                    bomber.y = start.getCenter().y;
                    this.rerender();
                }
            }
        }
        for (const entity of [...this.entites]) {
            entity.doTick();
        }
        if (this.shouldRerender) {
            this.shouldRerender = false;
            rerender();
        }
    };

    rerender: () => void = () => {
        this.shouldRerender = true;
    };

    private lastId: number = 0;

    nextId() {
        return ++this.lastId;
    }
}