import * as React from "react";
import {PositionedEntity} from "../entities/PositionedEntity";

export function ImageComponent({entity, imageName, color, direction}: {
    entity: PositionedEntity,
    imageName: () => string,
    color?: string,
    direction?: () => number
}) {
    const state = entity.useState();
    const tileSize = entity.game.arena.tileSize;
    return (<>
        {color && <circle cx={state.x} cy={state.y} r={10} fill={color}/>}
        <g transform={`translate(${state.x - tileSize / 2},${state.y - tileSize / 2})`}>
            <image
                href={imageName()}
                width={tileSize}
                height={tileSize}
                transform={direction !== undefined ? `rotate(${direction() * (180 / Math.PI) - 90},${tileSize / 2},${tileSize / 2})` : undefined}
                // onError={e => (e.target as any).style.display = 'none'}
                key={"Entity#" + entity.id}
            />
        </g>
    </>);
}
