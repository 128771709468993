import * as React from "react";
import {TimedEntity} from "./TimedEntity";
import {Game} from "./Game";
import {Tile} from "./Tile";
import {Bomber} from "./Bomber";
import {Entity} from "./Entity";


export class Explosion extends TimedEntity {

    constructor(game: Game, tile: Tile) {
        super(game, 0.5 * 1000 / game.tickTimeMS);
        if (tile) {
            this.x = tile.getCenter().x;
            this.y = tile.getCenter().y;
        }
    }

    render() {
        return <rect
            x={this.x - 12}
            y={this.y - 12}
            width={24} height={24}
            fill="red"
            key={this.id}/>;
    }

    collides(other: Entity): boolean {
        if (other instanceof Bomber) {
            other.hit();
        }
        return false;
    }
}