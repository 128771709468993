import {PositionedEntity} from "./PositionedEntity";
import {Game} from "./Game";

export abstract class TimedEntity extends PositionedEntity {
    private lifetime: number;

    constructor(game: Game, lifetimeInTicks: number) {
        super(game);
        this.lifetime = lifetimeInTicks;
    }

    doTick() {
        this.lifetime--;
        if (this.lifetime === 0) {
            this.delete();
        }
    }
}