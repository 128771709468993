import {Game} from "./Game";
import * as React from "react";
import * as _ from "lodash";

export abstract class Entity {
    public readonly game: Game;
    public readonly id: number;

    constructor(game: Game) {
        this.game = game;
        this.id = game.nextId();
        game.entites.push(this);
    }

    abstract doTick(): void;

    abstract render(): React.ReactNode;

    protected notify: () => void = () => {
    };

    useState() {
        const [repaintCounter, setRepaintCounter] = React.useState(0);
        this.notify = () => setRepaintCounter(repaintCounter + 1);
    }

    public delete() {
        _.remove(this.game.entites, entity => entity === this);
        this.game.rerender();
    }
}