import {Entity} from "./Entity";
import {Game} from "./Game";
import {Tile} from "./Tile";
import * as _ from "lodash";
import * as React from "react";
import {ImageComponent} from "../components/ImageComponent";

const gamepadDeadZone = 0.2;

export abstract class PositionedEntity extends Entity {

    private _tile?: Tile;

    get tile(): Tile | undefined {
        return this._tile;
    }

    constructor(game: Game) {
        super(game);
        this.updateTileLink();
    }

    private _x: number = -1;

    public get x(): number {
        return this._x;
    }

    public set x(value: number) {
        this._x = value;
        this.updateTileLink();
        this.notify();
    }

    private _y: number = -1;

    public get y(): number {
        return this._y;
    }

    public set y(value: number) {
        this._y = value;
        this.updateTileLink();
        this.notify();
    }

    private updateTileLink() {
        const newTile = this.game.arena.getTileOf(this);
        if (newTile !== this._tile) {
            this.unsetTile();
            this._tile = newTile;
            if ( newTile ) {
                newTile.who.push(this);
                newTile.who.forEach(other=>{
                    other.meets(this);
                    this.meets(other);
                });
            }
        }
    }

    private unsetTile() {
        if (this._tile) {
            _.remove(this._tile.who, entity => entity === this);
            this._tile = undefined;
        }
    }

    delete() {
        this.unsetTile();
        super.delete();
    }

    /**
     * @returns true if explosion is blocked
     */
    hit(): boolean {
        return false;
    }

    collides(other: Entity) {
        return true;
    }

    render(): React.ReactNode {
        return <ImageComponent entity={this} imageName={()=>this.imageName()} key={"entity-"+this.id}/>
    }

    protected imageName(): string {
        return "none";
    }

    public meets(other: Entity) {

    }

    useState(): { x: number, y: number } {
        super.useState();
        return {x: this.x, y: this.y};
    }

    protected move(amount: number, direction: "x" | "y", speed: number, size: number) {
        let collision = false;
        const max = direction === "x" ? this.game.maxX : this.game.maxY;
        if (Math.abs(amount) > gamepadDeadZone) {
            let newValue = Math.max(Math.min(this[direction] + amount * speed, max), 0);
            for (const alt of [-size, +size]) {
                const newTile = this.game.arena.getTileAt(
                    (direction === "x" ? newValue + Math.sign(amount) * size : this.x + alt) || 0,
                    (direction === "y" ? newValue + Math.sign(amount) * size : this.y + alt) || 0);
                if (newTile !== this.tile) {
                    if (!newTile || newTile.collides(this) ||
                        newTile.who.filter(entity => entity.collides(this, )).length > 0) {
                        if (!this.tile) return true;
                        collision = true;
                        newValue = this.tile.getCenter()[direction] + Math.sign(amount) *
                            (this.game.arena.tileSize * 0.5 - size - 1);
                    }
                }
            }
            this[direction] = newValue;
        }
        return collision;
    }
}