import {PositionedEntity} from "./PositionedEntity";
import {Bomber} from "./Bomber";
import {Game} from "./Game";
import {Entity} from "./Entity";

export enum PowerupType {
    BOMB_MAX = "bomb+",
    BOMB_POWER = "power+",
    SPEED = "speed+",
    BOMB_MAX_DOWN = "bomb-",
    BOMB_POWER_DOWN = "power-",
    SPEED_DOWN = "speed-",
    KICK = "kick",
}

export class Powerup extends PositionedEntity {

    type: PowerupType;

    constructor(game: Game, type: PowerupType) {
        super(game);
        this.type = type;
    }

    doTick(): void {
    }

    protected imageName(): string {
        return `img/powerup-${this.type}.png`;
    }

    collides(other: Entity): boolean {
        return false;
    }

    meets(other: Entity) {
        if (other instanceof Bomber) {
            const bomber: Bomber = other;
            switch (this.type) {
                case PowerupType.BOMB_MAX:
                    bomber.maxBombs = bomber.maxBombs + 1;
                    break;
                case PowerupType.BOMB_POWER:
                    bomber.power = bomber.power + 1;
                    break;
                case PowerupType.SPEED:
                    bomber.speed = bomber.speed + 1;
                    break;
                case PowerupType.BOMB_MAX_DOWN:
                    if (bomber.maxBombs > 1) {
                        bomber.maxBombs = bomber.maxBombs - 1;
                    }
                    break;
                case PowerupType.BOMB_POWER_DOWN:
                    if (bomber.power > 1) {
                        bomber.power = bomber.power - 1;
                    }
                    break;
                case PowerupType.SPEED_DOWN:
                    if (bomber.speed > 4) {
                        bomber.speed = bomber.speed - 1;
                    }
                    break;
                default:
                    break;
            }
            this.delete();
            return false;
        }
    }

    hit(): boolean {
        this.delete();
        return true;
    }
}